export const Detail = (props) => {
  console.log(props)
    return (
      <div id='media'>
        <div className='container'>
        <div className='section-title text-center'>
          <h2> {props.data2 ? props.data2.detail : 'Loading'}</h2>
          </div>    
          <div className='row' >
            <div className=' col-md-4 col-lg-4'></div>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-xs-12 col-md-4 col-lg-4' >
                    <div className='testimonial'>
                      <div className='testimonial-image'>
                        {' '}
                        <img className='img-responsive' src={d.img}  alt=''  />{' '}
                      </div>
                      </div>
                    </div>
                    
                ))
              : 'loading'}
                       <div className=' col-md-4 col-lg-4'></div>
          </div>
          </div>
          </div>
    )}