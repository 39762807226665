import React,{useState,useEffect} from "react";
import { Transition, animated } from "react-spring";
var img;
// var bg_new;
var img1;
export const Faqs = (props) => {
const [state,setstate] = useState(true);
const [toggle2,settoggle] = useState(true);
useEffect(() => {
if(state){
  setTimeout(() => {
    document.getElementById('slide').style.height = '0'
  setstate(false)
  }, 2000);
  
}
 
//   if(state){
//     document.getElementById('slide').style.height = '1544.39px';
//    setstate(false)
//   }else{
// setTimeout(() => {
 
  
// }, 5000);
//    ;

//   }
   
    
  
}, [state])
function toggle(){
  console.log("lllllllllllllllll",state)
  
  settoggle(!toggle2)
  console.log("bbbbbbbbbbb",state)
  
  if(toggle2){
    document.getElementById('slide').style.height = '100%';
    settoggle(false)
    
  }else{
    document.getElementById('slide').style.height = '0';
    settoggle(true)
  }
  
 
}
  if (sessionStorage.getItem("lang") === "th") {
    img = 'img/faqs/Buy-VSNT-1TH.png '
    img1='img/faqs/VSNT-TokenA4-Creatfont-01-01.svg'
    // bg_new = "faqs_th_new expand";
  } else {
    img = 'img/faqs/Buy-VSNT-1Eng.png '
    img1='img/faqs/VSNT-TokenA4-Eng-Creatfont-01.svg'
    // bg_new = "faqs_new expand";
  }
  
  
  return (
    <div id="faqs" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.head : "loading..."}</h2>
          
        </div>


        <div className="row">
          <img src={img}  style={{position:'relative', width:'100%'}}>
        
          </img>
          {/* <div style={{height:'30px', border:'solid 1px '}}  > */}
          <h4 style={{color:'darkred'}}>{props.data ? props.data.more : "loading..."}</h4>
          <icon className="fa fa-caret-down" style={{ fontSize:'25px', color:'darkred'}}  onClick={toggle}></icon>
          
          {/* </div> */}
          </div>
          <img src={img1} className="expand img-responsive" id="slide" style={{marginTop:'0px'}}></img>
        
     
          <div className="row" style={{marginTop:'20px'}}>
          
           
        </div>
        <h3>{props.data ? props.data.video1 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px' }}>
        <video  height={480} controls>
              <source src="video/645119347.961820.mp4" type="video/mp4" />
            </video>
        </div>
        <h3 style={{marginTop:'100px'}}>{props.data ? props.data.video2 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px' }}>
        <video  height={480} controls>
              <source src="video/645286543.558348.mp4" type="video/mp4" />
            </video>
            </div>
            <h3 style={{marginTop:'100px'}}>{props.data ? props.data.video3 : "loading..."}</h3>
        <div className="row" style={{backgroundColor:'black',marginTop:'50px' }}>
        <video  height={480} controls>
              <source src="video/645375677.992225.mp4" type="video/mp4" />
            </video>

        </div>

    
      </div>
    </div>
  );
};
