
import React,{useState,useEffect} from 'react'
import Axios from "axios";


export default function Clamtoken() {


    const [newaddress, setaddress] = useState("");
// useEffect(() => {
//     document.getElementById("popup-1").classList.toggle("active");
   
// }, [])

const addAddress = () => {
  Axios.post('http://localhost/api/php/vsnt_api.php', {
    address: newaddress
  }).then((response) => {
    if (response.status == 0) {
      alert("Your wallet address send success")
    } else {
      alert("Your wallet address send invalid")
    }
  })
}
    function togglePopup() {
      console.log("toggle")
      document.getElementById("popup-1").classList.toggle("active");
    }

    return (
        <div className="popup" id="popup-1">
        <div className="overlay" />
        <div className="content">
        <i className="fas fa-times-circle" onClick={togglePopup} style={{float:'right',fontSize:'20px'}}></i>
         
          <div className="d-flex flex-column justify-content-center" style={{display:'flex',marginTop:'30px'}}>
         
          <input 
              type="text" 
              name="wallet" 
              id="wallet"
              className="form-control"
              placeholder="Add your wallet ID of VSNT (valid 21/06/2021)" 
              style={{ fontSize:'8px', maxWidth:'400px'}} 
              onChange={(event) => {
                setaddress(event.target.value)
              }}
              />
              <button type="submit" className="btn btn-primary" id="bnt-address" style={{maxHeight:'35px', marginLeft:'10px'}} onClick={() =>{addAddress(); togglePopup() } }>Send</button>
          
          </div>
        </div>
      </div>
    )
}










