export const Features = (props) => {
  console.log("feature", props.data)
    return (
      <div id='features' className='text-center'>
        
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>{props.data2 ? props.data2.feat : 'Loading'}</h2>
          </div>
          <div className='container '> 
        
            {props.data
              ? props.data.map((d, i) => (
            
                  <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3 card-features'>
                    {' '}
                    <i className={d.icon} ></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                 
                ))
              : 'Loading...'}
    </div>
      
      </div>
    )
  }
  