import {  Link } from "react-router-dom";
//import Docs from "./docs";
import { useState } from "react";
import Axios from "axios";

export const Navigation = (props) => {
  const [toggle2, settoggle] = useState(true);
  function showAlert() {
    document
      .getElementsByClassName("alert")[0]
      .setAttribute(
        "style",
        "visibility:'none'; width:100%;height:71px; font-size:20px;width:100%"
      );
    setTimeout(() => {
      document
        .getElementsByClassName("alert")[0]
        .setAttribute(
          "style",
          "visibility:hidden; height:71px; font-size:20px; opacity: 0;transition: visibility 0s linear 0.5s, opacity 0.5s linear;"
        );
    }, 500);
  }

  const [newaddress, setaddress] = useState("");
  const addAddress = () => {
    Axios.post("http://localhost:3002/findAddress", {
      address: newaddress,
    }).then((response) => {
      if (response.data.status == 0) {
        Axios.post("http://localhost:3002/addAddress", {
          address: newaddress,
        });
        alert("Your wallet address send success");
      } else {
        alert("Your wallet address send invalid");
      }
    });
  };
  function togglePopup() {
    document.getElementById("popup-1").classList.toggle("active");
  }

  function toggle() {
    console.log(toggle2);

    if (toggle2) {
      document.getElementById("slide1").style.width = "100%";

      settoggle(false);
    } else {
      document.getElementById("slide1").style.width = "0";
      settoggle(true);
    }
  }

function changeFontToTh(){
  let element = document.getElementsByClassName('en')
  element.className = 'th'
  console.log(element.className)
}
function changeFontToEn(){
  let element = document.getElementsByClassName('th')

  element.className = 'en'
}

  return (
    <div className="container">
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div
          className="alert alert-success navbar-fixed-top"
          role="alert"
          align="center"
          style={{ visibility: "hidden", height: "71px" }}
        >
          copy succsess
        </div>
        <div
          className="navbar-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',columnGap:'10px'}}>
            <a className="navbar-brand page-scroll" href="#page-top" style={{padding:'10px 0'}}>
              <h2 style={{margin:'0'}}>VSNT</h2>
            </a>
            <h6>Address Token</h6>
            <icon
              className="fa fa-caret-right"
              style={{ fontSize: "25px", color: "darkred" }}
              onClick={toggle}
            ></icon>
            <div className="expand-right" id="slide1" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <a
                onClick={() => {
                  navigator.clipboard.writeText(props.data.wallet);
                  showAlert();
                }}
              >
                
                <i
                
                  className="fa fa-copy"
                  style={{ color: "grey",display:'flex',flexDirection:'row'}}
                >
                  <a style={{paddingLeft:'5px'}}>  {(window.screen.width>=600) && props.data? props.data.wallet :"0x4....7f5"}</a>
                </i>
              
              </a>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              style={{
                padding: "8px 10px 8px 10px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {props.data ? props.data.feat : "Loading"}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                {props.data ? props.data.about : "Loading"}
              </a>
            </li>
            <li>
              <a href="#concept" className="page-scroll">
                {props.data ? props.data.concept : "Loading"}
              </a>
            </li>
            <li>
              <a href="#tokenomic" className="page-scroll">
                {props.data ? props.data.token : "Loading"}
              </a>
            </li>
            <li>
              <a href="#service" className="page-scroll">
                {props.data ? props.data.service : "Loading"}
              </a>
            </li>
            <li>
              <a href="#roadmap" className="page-scroll">
                {props.data ? props.data.road : "Loading"}
              </a>
            </li>
            <li>
              <a href="#faqs" className="page-scroll">
                {props.data ? props.data.faq : "Loading"}
              </a>
            </li>
            {/* <li>
                <a href='#portfolio' className='page-scroll'>
                  {props.data ? props.data.partner : 'Loading'}
                </a>
              </li> */}
            {/* <li>
                <a href='#media' className='page-scroll'>
                  {props.data ? props.data.media : 'Loading'}
                </a>
              </li> */}
            {/* <li>
                <a href='#team' className='page-scroll'>
                  {props.data ? props.data.team : 'Loading'}
                </a>
              </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                {props.data ? props.data.contact : "Loading"}
              </a>
            </li>

            <li>
              <Link to="/docs" className="">
                {props.data ? props.data.docs : "Loading"}
              </Link>
            </li>

            <li className="mydropdown">
              <div className="dropdown" style={{ margin: "2px 0 0 20px" }}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  style={{ marginTop: "-10px", marginLeft: "0px" }}
                >
                  EN/TH
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "50px" }}
                >
                  <li style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className="dropdown-item"
                      onClick={() => {

                        sessionStorage.setItem("lang", "th");
                        sessionStorage.setItem("frombutton", "true");
                        //changeFontToTh();
                        window.location.reload();
                      }}
                      style={{ marginLeft: "25px" }}
                    >
                      TH
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        sessionStorage.setItem("lang", "en");
                        sessionStorage.setItem("frombutton", "true");
                        //changeFontToEn();
                        window.location.reload();
                      }}
                      style={{ marginLeft: "25px" }}
                    >
                      EN
                    </div>
                  </li>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
