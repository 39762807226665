export const Listing = (props) => {
    return (
<div id='media' >
<div className='container'>
  <div className='section-title text-center'>
  <h2>{props.data2 ? props.data2.listing : 'Loading'}</h2>
  </div>    
  <div className='row' >
    <div className='col-md-2 col-lg-2'></div>
    {props.data
      ? props.data.map((d, i) => (
          <div key={`${d.name}-${i}`} className='col-md-4 col-xs-12 col-lg-4 testimonial'>
           
              <div className='testimonial-image'>
                {' '}
                <img src={d.img} alt='' />{' '}
              </div>
         
            </div>
        ))
      : 'loading'}
 <div className='col-md-2  col-lg-2'></div>
  </div>
</div>
</div>
)
  }