var bg1,bg2,bg3,bg4,bg5,bg6;

export const Tokenomic = (props) => {
  console.log(props)
  if(sessionStorage.getItem('lang')==='th'){
     bg1 ='img/tokenomic/1-th.png';
     bg2 ='img/tokenomic/2-th.png';
     bg3 ='img/tokenomic/3-th.png';
     bg4 ='img/tokenomic/4-th.png';
     bg5 ='img/tokenomic/5-th.png';
     bg6 ='img/tokenomic/6-th.png';
 }else{
    bg1 ='img/tokenomic/1.png';
    bg2 ='img/tokenomic/2.png';
    bg3 ='img/tokenomic/3.png';
    bg4 ='img/tokenomic/4.png';
    bg5 ='img/tokenomic/5.png';
    bg6 ='img/tokenomic/6.png';
 }
    return (
      <div id='tokenomic' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>{props.data ? props.data.head : 'loading...'}</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
          <div className='row'>
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg1}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg1}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
    
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg2}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg2}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
             
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg3}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg3}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
             
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg4}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg4}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
             
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg5}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg5}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              
            <div className='tokenomic-items'>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='tokenomic-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href={bg6}
                      title='Project Title'
                      data-lightbox-gallery='gallery1'
                    >
                      <img
                        src={bg6}
                        className='img-responsive'
                        alt='Project Title'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
                </div>
              </div>         
              
    )
  }
  