import MediaQuery from 'react-responsive'
import Claimtoken from './clamtoken'
function togglePopup() {
  document.getElementById("popup-1").classList.toggle("active");
}

export const Header = (props) => {
    return (
      <header id='header'>
        <MediaQuery minWidth={320}>
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  <a
                    href='https://pancakeswap.finance/'
                    className='btn btn-custom btn-lg page-scroll'
                  >
                   {props.data ? props.data.buy : 'Loading'}
                  </a>{' '}
                  <a
                    href='#features'
                    className='btn btn-custom btn-lg page-scroll'
                  >
                    {props.data ? props.data.learn : 'Loading'}
                  </a>{' '}
                  <div
                   
                    className='btn btn-custom btn-lg page-scroll' onClick={togglePopup}
                  >
                    
                   {props.data ? props.data.claim : 'Loading'}
                  </div>
                  <Claimtoken className='popup' id='popup-1'></Claimtoken>{' '}
                  <a
                    href=''
                    className='btn btn-custom btn-lg page-scroll'
                    style={{whiteSpace:'pre-wrap'}}>
                   {props.data ? props.data.google_form : 'Loading'}
                  </a>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        </MediaQuery>
      </header>
    )
  }
  