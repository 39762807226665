export const About = (props) => {
 console.log(props)
  return (
    <div id='about'>
      <div className='container'>
            <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about/about.png' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
              <h2>{props.data ? props.data.head : 'loading...'}</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h2>{props.data ? props.data.head1 : 'loading...'}</h2>
              </div>
      </div>
      
              <div className='container'>
              <div className='section-title'>
              <div className='about-text'>
                <div className='row'>
              <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                {''}
                  <a
                    href='img/about/1.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/1.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>
                  <p style={{margin:'0'}}>{props.data ? props.data.text : 'Loading'}</p>
                 
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'  style={{padding:'0'}}>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/about/2.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/2.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                  <p style={{margin:'0px'}}>{props.data ? props.data.text1 : 'Loading'}</p>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/about/3.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/3.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                  <p>{props.data ? props.data.text2 : 'Loading'}</p>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/about/4.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/4.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                  <p>{props.data ? props.data.text3 : 'Loading'}</p>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/about/5.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/5.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                  <p>{props.data ? props.data.text4 : 'Loading'}</p>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
              <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/about/6.png'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/about/6.png'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                  <p>{props.data ? props.data.text5 : 'Loading'}</p>
                </div>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  )
}
