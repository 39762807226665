var bg;
export const Concept = (props) => {
  if(sessionStorage.getItem('lang')==='th'){
      bg = 'concept_th';
  }else{
     bg ='concept';
  }
    return (
      <div id='concept' className='text-center'>
            <div className='container'>
            <div className='section-title'>
            <h2>{props.data ? props.data.head : 'loading...'}</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
              <div className='row'>
                <div className={bg}>
                </div>
              </div>
            </div>
      </div>
    )
  }