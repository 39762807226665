import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFReader = () => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  return (
    <div style={{overflowX:'hidden'}}>
 
     <nav id='menu' className='navbar navbar-default navbar-fixed-top' style={{position:'relative' ,width:'100%'}}>
          <div className='navbar-header' >
          <a className='navbar-brand page-scroll' href='/'>
             <h2>VSNT</h2>
            </a>{' '}
            </div>
            </nav>
           
      <section
        id="pdf-section"
       style={{position:'relative',display:'flex',flexDirection:'column', alignItems:'center',width:'100%'}}
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file="/assets/docs/VASSANA-TOKEN-D2.pdf"
        />
        
        <Document
          file="/assets/docs/VASSANA-TOKEN-D2.pdf"
          onLoadSuccess={onDocumentLoadSuccess} 
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
        
      </section>
    </div>
  );
};

export default PDFReader;
